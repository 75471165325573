#root{
    top:0;
    left:0;
    position:fixed;
    width:100%;
    background: linear-gradient(to right, #ff5e1e, #ff7f50, #ff7f50, #ff5e1e);
}

::-webkit-scrollbar {
    display: none;
}
html {
    height: 100%;
}

body {
    min-height: 100%;
}